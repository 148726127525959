let priorScollLocation: number = 0;

class Slider {
  idBeginsWith: string;
  currentIdx: number;
  autoNext: boolean;
  intervalId: number;

  constructor(idBeginsWith: string, autoNext: boolean) {
    this.idBeginsWith = idBeginsWith;
    this.currentIdx = 0;
    this.next();
    this.autoNext = autoNext;
    this.intervalId = -1;
    if (this.autoNext) {
      this.intervalId = setInterval(this.next.bind(this), 5000);
    }
  }

  userPrev(): void {
    this.prev();
    if (this.autoNext) {
      clearInterval(this.intervalId);
      this.intervalId = setInterval(this.prev.bind(this), 5000);
    }
  }

  userNext(): void {
    this.next();
    if (this.autoNext) {
      clearInterval(this.intervalId);
      this.intervalId = setInterval(this.next.bind(this), 5000);
    }
  }

  prev(): void {
    const divs: NodeListOf<Element> = document.querySelectorAll(
      `[id^='${this.idBeginsWith}']`
    );
    divs.forEach(this.processDiv.bind(this));
    this.currentIdx--;
    if (this.currentIdx < 0) {
      this.currentIdx = divs.length - 1;
    }
  }

  next(): void {
    const divs: NodeListOf<Element> = document.querySelectorAll(
      `[id^='${this.idBeginsWith}']`
    );
    divs.forEach(this.processDiv.bind(this));
    this.currentIdx++;
    if (this.currentIdx >= divs.length) {
      this.currentIdx = 0;
    }
  }

  processDiv(value: Element, key: number): void {
    if (key == this.currentIdx) {
      value.classList.remove("opacity-0");
      value.classList.add("opacity-100");
    } else {
      value.classList.remove("opacity-100");
      value.classList.add("opacity-0");
    }
  }

  reset(idBeginsWith: string): void {
    this.idBeginsWith = idBeginsWith;
    this.currentIdx = 0;
    this.next();
    if (this.autoNext) {
      clearInterval(this.intervalId);
      this.intervalId = setInterval(this.next.bind(this), 5000);
    }
  }
}

new Slider("hero-slideshow-", true);
let roomSlider: Slider = new Slider("room-00-slideshow-", true);
let gallerySlider: Slider = new Slider("gallery-slideshow-", false);

function activateRoom(roomId: number): void {
  const contents: NodeListOf<Element> = document.querySelectorAll(
    "[id^='room-content-']"
  );
  contents.forEach(function (value: Element, key: number): void {
    if (key == roomId) {
      value.classList.remove("hidden");
      value.classList.add("flex");
    } else {
      value.classList.add("hidden");
      value.classList.remove("flex");
    }
  });
  const items: NodeListOf<Element> =
    document.querySelectorAll("[id^='room-item-']");
  items.forEach(function (value: Element, key: number): void {
    if (key == roomId) {
      value.classList.add("text-cyanish-400");
    } else {
      value.classList.remove("text-cyanish-400");
    }
  });
  const links: NodeListOf<Element> =
    document.querySelectorAll("[id^='room-link-']");
  links.forEach(function (value: Element, key: number): void {
    if (key == roomId) {
      value.classList.remove("border-transparent");
      value.classList.add("border-cyanish-400");
    } else {
      value.classList.add("border-transparent");
      value.classList.remove("border-cyanish-400");
    }
  });
  roomSlider.reset(`room-${roomId.toString().padStart(2, "0")}-slideshow-`);
}

function getScrollLocation(): number {
  return Math.max(
    window.scrollY ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0
  );
}

// scroll detection
//    https://tailwind-elements.com/snippets/tailwind/tailwindelements/3681385?view=side#js-tab-view
//    https://stackoverflow.com/a/20478983/1913816
//      return window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
window.onscroll = onScroll;

function onScroll(): void {
  const scrollLocation: number = getScrollLocation();
  onScrollNav(scrollLocation);
  onScrollReturnToTop(scrollLocation);
  priorScollLocation = scrollLocation;
}

function onScrollNav(scrollLocation: number): void {
  const nav: Element | null = document.querySelector("#nav");
  if (nav === null) return;
  const logos: NodeListOf<HTMLImageElement> = document.querySelectorAll(
    "[id^='logo-']"
  ) as NodeListOf<HTMLImageElement>;
  if (priorScollLocation == 0 && scrollLocation > 0) {
    nav.classList.remove("border-transparent", "bg-opacity-0", "text-white");
    nav.classList.add("bg-opacity-95", "text-slate-600");
    // hidden because I don't want to make two different nav bars so they can all be absolute w/ z-indexes
    // otherwise just opacity leaves them stacked and we want to trigger opacity transition
    logos.forEach(function (logo: HTMLImageElement, key: number): void {
      if (key == 0) {
        logo.classList.add("opacity-0");
        logo.classList.add("hidden");
      } else {
        logo.classList.remove("hidden");
        // https://cloudfour.com/thinks/transitioning-hidden-elements/
        //    force repaint to unhide and allow transition, hope it's not expensive, but it shouldn't happen on
        //    intial load, so shouldn't affect site metrics
        // @ts-expect-error
        const repaint: number = logo.offsetHeight;
        logo.classList.remove("opacity-0");
      }
    });
  } else if (priorScollLocation > 0 && scrollLocation == 0) {
    nav.classList.add("border-transparent", "bg-opacity-0", "text-white");
    nav.classList.remove("bg-opacity-95", "text-slate-600");
    logos.forEach(function (logo: HTMLImageElement, key: number): void {
      if (key == 0) {
        logo.classList.remove("hidden");
        // https://cloudfour.com/thinks/transitioning-hidden-elements/
        //    force repaint
        console.log(logo.offsetHeight);
        logo.classList.remove("opacity-0");
      } else {
        logo.classList.add("opacity-0");
        logo.classList.add("hidden");
      }
    });
  }
}

function onScrollReturnToTop(scrollLocation: number): void {
  const nav: Element | null = document.querySelector("#nav");
  if (nav === null) return;
  const rect: DOMRect = nav.getBoundingClientRect();

  const returnToTop: Element | null = document.querySelector("#return");
  if (returnToTop === null) return;
  if (scrollLocation > rect.height) {
    returnToTop.classList.remove("opacity-0");
  } else {
    returnToTop.classList.add("opacity-0");
  }
}

function showModal(startingIdx: number): void {
  const modal: Element | null = document.getElementById("galleryModal");
  if (modal === null) return;
  gallerySlider.currentIdx = startingIdx;
  gallerySlider.next();
  modal.classList.remove("hidden");
}

function closeModal(): void {
  const modal: Element | null = document.getElementById("galleryModal");
  if (modal === null) return;
  modal.classList.add("hidden");
}

function openNarrowNavMenu(): void {
  const menu: Element | null = document.getElementById("narrowNavMenu");
  if (menu === null) return;
  menu.classList.remove("hidden");
}

function closeNarrowNavMenu(): void {
  const menu: Element | null = document.getElementById("narrowNavMenu");
  if (menu === null) return;
  menu.classList.add("hidden");
}

document.addEventListener("keydown", (event: KeyboardEvent): void => {
  if (event.key === "Escape") {
    closeModal();
    closeNarrowNavMenu();
  }
});

document.addEventListener("click", (event: MouseEvent): void => {
  if (event.target === null) return;
  const menu: Element | null = document.getElementById("narrowNavMenu");
  if (menu === null) return;
  const hamburger: Element | null = document.getElementById("hamburger");
  if (hamburger === null) return;
  if (
    !hamburger.contains(event.target as Node) &&
    !menu.contains(event.target as Node)
  ) {
    closeNarrowNavMenu();
  }
});
